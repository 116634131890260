import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
    background-color: #111;
    color: #fff;
    font-family: "Formula1", Arial, sans-serif;
    padding: 20px;
`;

const Title = styled.h1`
    color: #e10600;
    text-align: center;
    margin-bottom: 20px;
`;

const Select = styled.select`
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    padding: 5px;
    font-size: 1em;
    margin-bottom: 20px;
`;

const DriverGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Fixed card width */
    gap: 50px; /* Minimal spacing between boxes */
    justify-content: center;
    align-items: start;
    margin-top: 20px;
`;


const DriverCard = styled.div`
    width: 200px; /* Fixed width */
    height: 250px; /* Fixed height */
    background-color: #222;
    color: #fff;
    border: 2px solid ${(props) => props.teamColor || "#444"};
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden; /* Ensures content doesn’t overflow */
`;

const DriverName = styled.h3`
    margin: 10px 0;
`;

const TeamName = styled.p`
    font-weight: bold;
    color: ${(props) => props.teamColor || "#e10600"};
`;

const DriverStats = styled.div`
    margin-top: 10px;
    text-align: center;

    p {
        margin: 5px 0;
    }
`;

const Drivers = () => {
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(new Date().getFullYear());
    const [drivers, setDrivers] = useState([]);

    // Fetch available seasons
    useEffect(() => {
        axios
            .get("/api/seasons")
            .then((response) => {
                setSeasons(response.data);
                setSelectedSeason(response.data[0] || new Date().getFullYear());
            })
            .catch((error) => console.error("Error fetching seasons:", error));
    }, []);

    // Fetch drivers for the selected season
    useEffect(() => {
        if (selectedSeason) {
            axios
                .get(`/api/drivers/${selectedSeason}`)
                .then((response) => setDrivers(response.data))
                .catch((error) => console.error("Error fetching drivers:", error));
        }
    }, [selectedSeason]);

    return (
        <Container>
            <Title>Kierowcy</Title>
            <Select
                value={selectedSeason}
                onChange={(e) => setSelectedSeason(e.target.value)}
            >
                {seasons.map((season) => (
                    <option key={season} value={season}>
                        {season}
                    </option>
                ))}
            </Select>
            <DriverGrid>
                {drivers.map((driver) => (
                    <DriverCard key={driver.driver_id} teamColor={driver.team_color}>
                        <DriverName>{driver.driver_name}</DriverName>
                        <TeamName teamColor={driver.team_color}>{driver.team_name}</TeamName>
                        <DriverStats>
                            <p>Wygrane: {driver.wins}</p>
                            <p>Podiumy: {driver.podiums}</p>
                            <p>Fastest Laps: {driver.fastest_laps}</p>
                            <p>DNFs: {driver.dnfs}</p>
                        </DriverStats>
                    </DriverCard>
                ))}
            </DriverGrid>
        </Container>
    );
};

export default Drivers;
