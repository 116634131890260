import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
    background-color: #111;
    color: #fff;
    padding: 20px;
    font-family: "Formula1", Arial, sans-serif;
`;

const Section = styled.div`
    margin-bottom: 30px;
`;

const Title = styled.h2`
    color: #e10600;
    text-transform: uppercase;
    margin-bottom: 20px;
`;

const Input = styled.input`
    padding: 10px;
    margin-bottom: 10px;
    width: 300px;
    border: 1px solid #444;
    background-color: #222;
    color: #fff;
    border-radius: 5px;
`;

const Select = styled.select`
    padding: 10px;
    margin-bottom: 10px;
    width: 320px;
    border: 1px solid #444;
    background-color: #222;
    color: #fff;
    border-radius: 5px;
`;

const Checkbox = styled.input`
    margin-left: 5px;
    vertical-align: middle;
`;

const Button = styled.button`
    padding: 10px 20px;
    background-color: #e10600;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #c90000;
    }
`;

const AddData = () => {
    const [teams, setTeams] = useState([]);
    const [driverName, setDriverName] = useState("");
    const [teamId, setTeamId] = useState("");
    const [season, setSeason] = useState(2024);

    const [raceName, setRaceName] = useState("");
    const [isSprint, setIsSprint] = useState(false);
    const [hasHappened, setHasHappened] = useState(false);
    const [raceDate, setRaceDate] = useState("");

    // Fetch teams
    useEffect(() => {
        axios.get("/api/teams").then((res) => setTeams(res.data));
    }, []);

    // Add Driver
    const addDriver = async () => {
        if (!driverName || !teamId) {
            alert("Please fill out all fields.");
            return;
        }

        try {
            await axios.post("/api/add-driver", {
                name: driverName,
                team_id: teamId,
                season,
            });
            alert("Driver added successfully!");
            setDriverName("");
            setTeamId("");
        } catch (err) {
            console.error("Error adding driver:", err);
        }
    };

    // Add Race
    const addRace = async () => {
        if (!raceName) {
            alert("Please enter the race name.");
            return;
        }

        try {
            await axios.post("/api/add-race", {
                name: raceName,
                season,
                race_date: raceDate,
                is_sprint: isSprint,
                has_happened: hasHappened,
            });
            alert("Race added successfully!");
            setRaceName("");
            setIsSprint(false);
            setRaceDate("");
            setHasHappened(false);
        } catch (err) {
            console.error("Error adding race:", err);
        }
    };

    return (
        <Container>
            <Section>
                <Title>Dodaj Kierowcę</Title>
                <Input
                    type="text"
                    placeholder="Nazwa Kierowcy"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                />
                <Select
                    value={teamId}
                    onChange={(e) => setTeamId(e.target.value)}
                >
                    <option value="">-- Wybierz Drużyne --</option>
                    {teams.map((team) => (
                        <option key={team.id} value={team.id}>
                            {team.name}
                        </option>
                    ))}
                </Select>
                <Select value={season} onChange={(e) => setSeason(e.target.value)}>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </Select>
                <Button onClick={addDriver}>Dodaj Kierowcę</Button>
            </Section>

            <Section>
                <Title>Dodaj Wyścig</Title>
                <Input
                    type="text"
                    placeholder="Nazwa Wyścigu"
                    value={raceName}
                    onChange={(e) => setRaceName(e.target.value)}
                />
                <Select value={season} onChange={(e) => setSeason(e.target.value)}>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </Select>
                <Input
                    type="date"
                    value={raceDate}
                    onChange={(e) => setRaceDate(e.target.value)}
                />
                <label>
                    Sprint?
                    <Checkbox
                        type="checkbox"
                        checked={isSprint}
                        onChange={(e) => setIsSprint(e.target.checked)}
                    />
                </label>
                <label>
                    Czy był?
                    <Checkbox
                        type="checkbox"
                        checked={hasHappened}
                        onChange={(e) => setHasHappened(e.target.checked)}
                    />
                </label>
                <Button onClick={addRace}>Dodaj Wyścig</Button>
            </Section>
        </Container>
    );
};

export default AddData;
