import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

const CalendarContainer = styled.div`
    padding: 20px;
    background-color: #111;
    color: #fff;
    font-family: "Formula1", Arial, sans-serif;
`;

const Title = styled.h1`
    text-align: center;
    color: #e10600;
    margin-bottom: 20px;
`;

const RaceList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 600px;
`;

const RaceItem = styled.li`
    background-color: #222;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background-color: #333;
    }
`;

const RaceName = styled.div`
    font-size: 1.2em;
`;

const RaceDetails = styled.div`
    font-size: 1em;
    color: #bbb;
`;

const RaceDate = styled.div`
    font-size: 1em;
    color: #bbb;
`;

const Calendar = () => {
    const [races, setRaces] = useState([]);

    useEffect(() => {
        axios
            .get("/api/calendar")
            .then((res) => setRaces(res.data))
            .catch((err) => console.error("Error fetching calendar races:", err));
    }, []);

    const calculateDaysUntil = (raceDate) => {
        const today = new Date();
        const raceDay = new Date(raceDate);
        const diffTime = raceDay - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    return (
        <CalendarContainer>
            <Title>Nadchodzące Wyścigi</Title>
            <RaceList>
                {races.length > 0 ? (
                    races.map((race) => (
                        <RaceItem key={race.id}>
                            <RaceName>
                                {race.name} ({race.season})
                            </RaceName>
                            <RaceDetails>
                                {calculateDaysUntil(race.race_date)} dni do wyścigu
                            </RaceDetails>
                            <RaceDate>
                                {new Date(race.race_date).toLocaleDateString("pl-PL")}
                            </RaceDate>
                        </RaceItem>
                    ))
                ) : (
                    <p>Brak nadchodzących wyścigów.</p>
                )}
            </RaceList>
        </CalendarContainer>
    );
};

export default Calendar;
