import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: #111;
    color: #fff;
    font-family: "Formula1", Arial, sans-serif;
`;

const Title = styled.h1`
    color: #e10600;
    text-align: center;
    margin-bottom: 20px;
`;

const Select = styled.select`
    margin: 10px 0;
    padding: 5px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #222;
    color: #fff;
`;

const ToggleContainer = styled.div`
    margin: 10px 0;
`;

const ToggleButton = styled.button`
    background-color: ${(props) => (props.isActive ? "#e10600" : "#333")};
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;

    &:hover {
        background-color: #e10600;
    }
`;

const ScrollableTableContainer = styled.div`
    width: 95%;
    overflow-x: auto; /* Enable horizontal scrolling */
    background-color: #222;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 20px auto;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background-color: #222;
`;

const TableHeader = styled.th`
    background-color: #e10600;
    color: #fff;
    padding: 10px;
    text-align: center;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #333; /* Alternate row color */
    }

    &:hover {
        background-color: #444;
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    border: 1px solid #444;
    color: #ddd;

    /* Apply dynamic team color to the team cell */
    background-color: ${(props) => props.teamColor || "transparent"};
    color: ${(props) => (props.teamColor ? "#fff" : "#ddd")};
`;

const Leaderboard = () => {
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState("");
    const [standings, setStandings] = useState([]);
    const [teams, setTeams] = useState([]);
    const [isDriverStandings, setIsDriverStandings] = useState(true);

    useEffect(() => {
        // Fetch all seasons
        axios
            .get("/api/seasons")
            .then((response) => {
                setSeasons(response.data);
                setSelectedSeason(response.data[0]); // Default to the first season
            })
            .catch((error) => console.error("Error fetching seasons:", error));
    }, []);

    useEffect(() => {
        // Fetch all teams
        axios
            .get("/api/teams")
            .then((response) => setTeams(response.data))
            .catch((error) => console.error("Error fetching teams:", error));
    }, []);

    useEffect(() => {
        // Fetch standings based on the selected season and toggle state
        if (selectedSeason) {
            const endpoint = isDriverStandings
                ? `/api/standings/drivers/${selectedSeason}`
                : `/api/standings/constructors/${selectedSeason}`;
            axios
                .get(endpoint)
                .then((response) => setStandings(response.data))
                .catch((error) => console.error("Error fetching standings:", error));
        }
    }, [selectedSeason, isDriverStandings]);

    // Helper function to get team color by name
    const getTeamColor = (teamName) => {
        const team = teams.find((t) => t.name === teamName);
        return team ? team.team_color : null;
    };

    return (
        <Container>
            <Title>{isDriverStandings ? "Klasyfikacja Kierowców" : "Klasyfikacja Drużyn"}</Title>

            <Select
                value={selectedSeason}
                onChange={(e) => setSelectedSeason(e.target.value)}
            >
                {seasons.map((season) => (
                    <option key={season} value={season}>
                        {season}
                    </option>
                ))}
            </Select>

            <ToggleContainer>
                <ToggleButton
                    isActive={isDriverStandings}
                    onClick={() => setIsDriverStandings(true)}
                >
                    Klasyfikacja Kierwców
                </ToggleButton>
                <ToggleButton
                    isActive={!isDriverStandings}
                    onClick={() => setIsDriverStandings(false)}
                >
                    Klasyfikacja Drużyn
                </ToggleButton>
            </ToggleContainer>

            <ScrollableTableContainer>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Pozycja</TableHeader>
                            {isDriverStandings ? <TableHeader>Kierowca</TableHeader> : <TableHeader>Drużyna</TableHeader>}
                            {isDriverStandings && <TableHeader>Drużyna</TableHeader>}
                            <TableHeader>Punkty</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {standings.map((entry, index) => (
                            <TableRow key={entry.id}>
                                <TableCell>{index + 1}</TableCell>
                                {isDriverStandings ? (
                                    <>
                                        <TableCell>{entry.driver_name}</TableCell>
                                        <TableCell
                                            teamColor={getTeamColor(entry.team_name)}
                                        >
                                            {entry.team_name}
                                        </TableCell>
                                    </>
                                ) : (
                                    <TableCell
                                        teamColor={getTeamColor(entry.constructor_name)}
                                    >
                                        {entry.constructor_name}
                                    </TableCell>
                                )}
                                <TableCell>{entry.total_points}</TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </ScrollableTableContainer>
        </Container>
    );
};

export default Leaderboard;
