import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
    background-color: #111;
    color: #fff;
    font-family: "Formula1", Arial, sans-serif;
    padding: 20px;
    min-height: 100vh;
`;

const Title = styled.h1`
    color: #e10600;
    text-align: center;
    margin-bottom: 20px;
`;

const Select = styled.select`
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    padding: 5px;
    font-size: 1em;
    margin-bottom: 20px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #222;
`;

const TableHeader = styled.th`
    background-color: #e10600;
    color: #fff;
    padding: 10px;
    text-align: center;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #333;
    }

    &:hover {
        background-color: #444;
    }

    ${(props) => props.fastestLap && `border: 3px solid purple;`}
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    border: 1px solid #444;
    color: #ddd;
`;

const RaceResults = () => {
    const [races, setRaces] = useState([]);
    const [selectedRace, setSelectedRace] = useState("");
    const [results, setResults] = useState([]);

    // Fetch all races and set the latest race by default
    useEffect(() => {
        axios
            .get("/api/races")
            .then((response) => {
                console.log("Races from API:", response.data); // Debugging log
                const filteredRaces = response.data.filter((race) => race.has_happened === 1); // Filter races that have happened
                setRaces(filteredRaces);

                if (filteredRaces.length > 0) {
                    const latestRace = filteredRaces[0];
                    setSelectedRace(latestRace.id);
                    fetchRaceResults(latestRace.id);
                }
            })
            .catch((error) => console.error("Error fetching races:", error));
    }, []);

    // Fetch race results
    const fetchRaceResults = (raceId) => {
        axios
            .get(`/api/race-results/${raceId}`)
            .then((response) => setResults(response.data))
            .catch((error) => console.error("Error fetching race results:", error));
    };

    // Handle race selection change
    const handleRaceChange = (e) => {
        const raceId = e.target.value;
        setSelectedRace(raceId);
        fetchRaceResults(raceId);
    };

    return (
        <Container>
            <Title>Wyniki Wyścigów</Title>
            <Select value={selectedRace} onChange={handleRaceChange}>
                <option value="">-- Wybierz wyścig --</option>
                {races.map((race) => (
                    <option key={race.id} value={race.id}>
                        {race.name} (Season: {race.season})
                    </option>
                ))}
            </Select>
            {results.length > 0 && (
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Pozycja</TableHeader>
                            <TableHeader>Kierowca</TableHeader>
                            <TableHeader>Punkty</TableHeader>
                            <TableHeader>Fastest Lap</TableHeader>
                            <TableHeader>DNF</TableHeader>
                            <TableHeader>DSQ</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result) => (
                            <TableRow key={result.position} fastestLap={result.fastest_lap}>
                                <TableCell>{result.position}</TableCell>
                                <TableCell>{result.driver_name}</TableCell>
                                <TableCell>{result.points}</TableCell>
                                <TableCell>{result.fastest_lap ? "Tak" : "Nie"}</TableCell>
                                <TableCell>{result.dnf ? "Tak" : "Nie"}</TableCell>
                                <TableCell>{result.dsq ? "Tak" : "Nie"}</TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};

export default RaceResults;
