import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #111;
    color: #fff;
`;

const Title = styled.h1`
    color: #e10600;
    margin-bottom: 20px;
`;

const Input = styled.input`
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #222;
    color: #fff;
    font-size: 1em;
`;

const Button = styled.button`
    background-color: #e10600;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;

    &:hover {
        background-color: #b00500;
    }
`;

const Admin = () => {
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const handleLogin = () => {
        const correctPassword = "krewetachuj"; // Replace with your desired password
        if (password === correctPassword) {
            setIsAuthenticated(true);
        } else {
            alert("Incorrect password!");
        }
    };

    return (
        <Container>
            <Title>Panel Admina</Title>
            {!isAuthenticated ? (
                <>
                    <Input
                        type="password"
                        placeholder="Podaj hasło admina"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button onClick={handleLogin}>Zaloguj się</Button>
                </>
            ) : (
                <>
                    <Button onClick={() => navigate("/add-results")}>Dodaj wyniki wyścigu</Button>
                    <Button onClick={() => navigate("/add-data")}>Dodaj dane</Button>
                </>
            )}
        </Container>
    );
};

export default Admin;
